<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
// @vue/component
@Component({
	name: 'Members'
})
export default class Members extends Vue {}
</script>
