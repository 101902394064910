<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->
<template>
	<div>
		<h2 class="h2-have-account">
			{{ $t('haveAnAccount') }}
			<BaseButton
				:label="$t('login')"
				:pill="false"
				variant="link"
				class="pl-0 loginButton"
				:automation-id="getAutomationId('registration-dialog')"
				:data-tracking-id="getAutomationId('registration-dialog')"
				@click="navigateToLogin()"
			/>
		</h2>
		<div v-if="brand && !error">
			<h2 class="h2-need-help">{{ $t('needHelp') }}</h2>
			<p>
				{{ $t('lostCard') }}<br />
				{{ $t('noCard') }}
			</p>
			<CallUsInformation
				:automation-id="getAutomationId('registration-dialog')"
				:show-toggle="true"
				@onError="onError()"
			/>
		</div>
	</div>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
/**
 * This component renders a button that toggles contact information
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import CallUsInformation from '@/components/common/CallUsInformation';
import BaseButton from '@/components/common/base/BaseButton';
import IdMixin from '@/mixins/id.js';
import { LOGIN } from '@/constants/Routes.js';

// @vue/component
@Component({
	name: 'NeedHelp',
	components: {
		BaseButton,
		CallUsInformation
	},
	mixins: [IdMixin],
	props: {}
})
export default class NeedHelp extends Vue {
	navigateToLogin() {
		this.$router.push({
			name: LOGIN
		});
	}

	data() {
		return {
			error: false
		};
	}

	onError() {
		this.error = true;
	}

	get brand() {
		// Get the brand from the URL
		let brand = this.$router?.currentRoute.query.brand;
		if (brand) brand = brand.toLowerCase(); // make sure lowercase
		if (brand === 'mbc' || brand === 'sbc') {
			// if it's a valid brand show info
			return brand;
		} else {
			// if no brand hide info
			return 'mbc';
		}
	}
}
</script>

<!-- *************************************************************************
	Styles
	************************************************************************* -->
<style lang="scss" scoped>
h2 {
	font-size: 24px;
	line-height: normal;
}

.h2-have-account {
	font-size: 18px;
}

.h2-need-help {
	font-size: 21px;
}
</style>
<i18n>
{
	"en": {
		"needHelp": "Need help?",
		"login": "Log in",
		"haveAnAccount": "Already have an account?",
		"lostCard": "Can’t find your card?",
		"noCard": "Haven’t received your card yet?"
	},
	"fr": {
		"needHelp": "Besoin d’aide?",
		"login": "Ouvrez une session",
		"haveAnAccount": "Vous avez déjà un compte?",
		"lostCard": "Vous avez perdu votre carte?",
      	"noCard": "Vous n’avez pas encore reçu votre carte?"
	}
}
</i18n>
