<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->
<template>
	<BRow class="justify-content-md-center">
		<BCol cols="12" lg="10">
			<RegistrationDialog
				v-model="member"
				:error-message="APIError"
				automation-id="registration"
				@submit="submitRegistration"
			/>
			<LoadingModal
				:modal-show="isLoading"
				:modal-text="''"
				automation-id="registration-loading-modal"
				@submit="submitForgotPassword()"
			/>
		</BCol>
		<ErrorModal
			:id="getUniqueId('error-boundary')"
			class="error-modal"
			name="error"
			:modal-show="isModalVisible"
			:automation-id="getAutomationId('error-boundary')"
			:error-message="$t('error.wentWrong')"
			@change="toggleModal()"
		/>
	</BRow>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->
<script>
/**
 * This component is responsible to render registration content
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import Authentication from '@/models/Authentication';
import { BRow, BCol } from 'bootstrap-vue';
import RegistrationDialog from '@/components/register/RegistrationDialog';
import ErrorModal from '@/components/common/ErrorModal';
import * as Routes from '@/constants/Routes.js';
import LoadingModal from '@/components/common/LoadingModal';
import IdMixin from '@/mixins/id';

// @vue/component
@Component({
	name: 'Registration',
	components: {
		RegistrationDialog,
		LoadingModal,
		BRow,
		ErrorModal,
		BCol
	},
	mixins: [IdMixin],
	props: {
		automationId: {
			type: String,
			default: ''
		}
	}
})
export default class Registration extends Vue {
	isModalVisible = false;

	member = {
		memberCardId: '',
		policyId: '',
		dob: '',
		email: null,
		password: '',
		confirmPassword: ''
	};
	error = {
		title: '',
		body: ''
	};
	APIErrorEn = null;
	APIErrorFr = null;

	get APIError() {
		return this.$store.state.i18n.locale === 'en' ? this.APIErrorEn : this.APIErrorFr;
	}

	get isLoading() {
		return this.$store.state.loading;
	}

	toggleModal() {
		this.isModalVisible = !this.isModalVisible;
	}

	/**
	 * Registration API call
	 */
	submitRegistration(member) {
		this.$store.dispatch('updateLoading', true);
		this.loading = true;
		// reset the API error just in case it is set.
		this.APIError = null;
		Authentication.register(member, this.$store.state.i18n.locale)
			.then((response) => {
				if (response && (response.status === 201 || response.status === 200)) {
					if (!response.data.isReregistering) {
						this.$router.push({ name: Routes.SUCCESS_REGISTRATION });
					} else {
						this.$router.push({ name: Routes.INVALID_EMAIL });
					}
				} else if (
					response?.status === 401 &&
					response?.data?.message &&
					response.data.message !== 'INVALID'
				) {
					this.APIErrorEn = response.data.messageEn;
					this.APIErrorFr = response.data.messageFr;
					this.$store.dispatch('updateLoading', false);
				} else if (response && response.status > 400) {
					if (response.data && response.data.message) {
						let header = response.data.title;
						if (!header) {
							header = this.$t('error.wentWrongHeader');
						}
						this.$store.dispatch('error', {
							name: 'registration-error',
							title: header,
							err: response.data.message
						});
					} else {
						this.toggleModal();
					}
				} else {
					this.$store.dispatch('error', {
						name: 'registration-error',
						title: null,
						err: this.$t('error.wentWrong')
					});
				}
			})
			.finally(() => {
				this.$store.dispatch('updateLoading', false);
				this.loading = false;
			});
	}
}
</script>

<!-- *************************************************************************
	STYLE
	************************************************************************* -->

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->
<i18n>
    {
        "en": {
			"error": {
				"wentWrongHeader": "Something went Wrong",
				"wentWrong": "Something's acting up and we couldn't save your information. Please try again!"
			}
        },
        "fr": {
			"error": {
				"wentWrongHeader": "Une erreur s’est produite",
				"wentWrong": "Quelque chose ne tourne pas rond et nous n’avons pas été en mesure d’enregistrer vos renseignements. Veuillez réessayer."
			}
        }
    }
    </i18n>
