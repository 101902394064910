<template>
	<Result
		:automation-id="getAutomationId('success-registration')"
		:title="$t('success.title')"
		:sub-title="$t('success.secondaryTitle')"
		:content="$t('success.content')"
		icon-color="success-color"
	/>
</template>

<script>
// This component renders the successful registration screen.
import Vue from 'vue';
import Component from 'vue-class-component';
import Result from '@/components/result/Result.vue';
import IdMixin from '@/mixins/id';

// @vue/component
@Component({
	name: 'SuccessRegistration',
	components: {
		Result
	},
	mixins: [IdMixin]
})
export default class SuccessRegistration extends Vue {}
</script>

<i18n>
{
    "en": {
        "success": {
            "title": "You're almost done!",
            "secondaryTitle": "We’ve emailed you a link to activate your account. ",
            "content": "Please <b>check your email</b> and <b>click the link provided</b> to access your account for the first time."
        }
    },
    "fr": {
        "success": {
            "title": "Vous y êtes presque!",
            "secondaryTitle": "Nous vous avons envoyé par courriel un lien d’activation pour votre compte.",
            "content": "Vérifiez <b>votre boîte de courriels</b> et <b>cliquez sur le lien</b> pour accéder à votre compte une première fois."
        }
    }
}
</i18n>
