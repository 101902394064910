<template>
	<Result
		:title="$t('title')"
		:has-body="false"
		:button="$t('button')"
		:automation-id="getAutomationId('active-result')"
	/>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Result from '@/components/result/Result';
import IdMixin from '@/mixins/id';

// @vue/component
@Component({
	name: 'ActivateResult',
	components: {
		Result
	},
	mixins: [IdMixin]
})
export default class ActivateResult extends Vue {}
</script>
<style lang="scss" scoped>
* ::v-deep button {
	margin-top: 15px;
	width: 100%;
	@media screen and (max-width: 576px) {
		padding: 0;
	}
}
* ::v-deep .iconSize {
	height: 60px;
	width: 60px;
}
* ::v-deep p {
	margin: 0;
}
</style>
<i18n>
{
    "en": {
		"title": "Your account has been activated.",
		"button": "Go to Login >"
    },
    "fr": {
		"title": "Votre compte a été activé.",
		"button": "Aller à la page de connexion >"
    }
}
</i18n>
