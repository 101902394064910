<template>
	<Result
		class="already-exists-result"
		icon="exclamation-circle"
		icon-type="fal"
		icon-color="error-color"
		:title="$t('title')"
		:secondary-title="''"
		:content="$t('primaryContent')"
		:secondary-content="$t('secondaryContent')"
		:is-failed="true"
		:automation-id="getAutomationId('already-exists-registration')"
	/>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Result from '@/components/result/Result';
import IdMixin from '@/mixins/id';

// @vue/component
@Component({
	name: 'AlreadyExistsRegistration',
	components: {
		Result
	},
	mixins: [IdMixin],
	props: {
		automationId: {
			type: String,
			default: ''
		}
	}
})
export default class AlreadyExistsRegistration extends Vue {}
</script>

<i18n>
{
    "en": {
        "title": "Looks like you already have an account with us!",
        "primaryContent": "To help you access your account, we've sent you instructions to the email we have on file.",
        "secondaryContent": "Don't have access to that email anymore or aren't sure which one you used? Reach out to our customer service team and we'll help you get access."
    },
    "fr": {
        "title": "Il semble que vous ayez d'un compte avec nous!",
        "primaryContent": "Pour vous aider à accéder à votre compte, nous vous avons envoyé des directives à l'adresse courriel dans votre dossier.",
        "secondaryContent": "Vous n'avez plus accès à ce courriel ou ne savez plus quelle adresse vous avez utilisé? Communiquez avec notre équipe du service à la clientèle qui vous aidera à retrouver vos accès."
    }
}
</i18n>
