<template>
	<div>
		<FormHeader
			:title="$t('title.header')"
			:secondary-title="$t('title.secondHeader')"
			:automation-id="getAutomationId('registration-dialog')"
		/>
		<BaseCard
			:img-src="require('@/assets/couple-using-tablet.jpg')"
			img-alt=""
			img-top
			:img-height="200"
			:automation-id="getAutomationId('registration-dialog')"
			two-columns
		>
			<template #error-message>
				<BaseAlert
					v-if="errorMessage"
					v-scroll-to
					variant="danger"
					:icon="['fal', 'exclamation-triangle']"
					class="error-body"
				>
					{{ errorMessage }}
				</BaseAlert>
			</template>
			<template #left>
				<h2>{{ $t('title.registerForm') }}</h2>
				<RegistrationForm
					v-model="member"
					:automation-id="getAutomationId('registration-dialog')"
					@submit="onSubmit"
				/>
			</template>
			<template #right>
				<NeedHelp />
			</template>
		</BaseCard>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import RegistrationForm from '@/components/register/RegistrationForm';
import BaseCard from '@/components/common/card/BaseCard';
import * as Routes from '@/constants/Routes.js';
import IdMixin from '@/mixins/id';
import NeedHelp from '@/components/common/NeedHelp';
import FormHeader from '@/components/common/form/FormHeader';
import BaseAlert from '@/components/common/alert/BaseAlert.vue';

// @vue/component
@Component({
	name: 'RegistrationDialog',
	components: {
		FormHeader,
		RegistrationForm,
		BaseCard,
		BaseAlert,
		NeedHelp
	},
	mixins: [IdMixin],
	props: {
		/**
		 * member value
		 */
		value: {
			type: Object,
			default: () => {}
		},
		automationId: {
			type: String,
			default: ''
		},
		errorMessage: {
			type: String,
			default: null
		}
	}
})
export default class RegistrationDialog extends Vue {
	member = {
		memberCardId: '',
		policyId: '',
		dob: '',
		email: null,
		password: '',
		confirmPassword: ''
	};

	/**
	 * Handle on click event.
	 */
	onSubmit() {
		this.$emit('submit', this.member);
	}

	navigateToLogin() {
		this.$router.push({
			name: Routes.LOGIN
		});
	}
}
</script>

<style lang="scss" scoped>
h2 {
	font-size: $h3-font-size;
}
.mobile_padding {
	margin-left: 0;
	margin-right: 0;
	@media (max-width: 576px) {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}
}
.registrationDialog {
	padding-bottom: 50px;
}
.registrationFormTitle {
	font-size: 24px;
	width: 311px;
}
.login_dialog {
	clear: both;
	border-radius: 15px;
	background-color: white;
}
.formBody ::v-deep img {
	object-position: 50% 30%;
	object-fit: cover;
	@media (max-width: 600px) {
		object-position: 0 0;
		object-fit: cover;
	}
}

.haveAccount ::v-deep p {
	font-family: 'Josefin Sans', sans-serif;
	font-size: 18px;
	color: #1b1b1b;
	margin-bottom: 0;
	padding-left: 0;
}
.haveAccount ::v-deep button {
	padding: 0;
	height: 27px;
	margin-left: 0;
}
.haveAccount {
	margin-bottom: 15px;
	padding-left: 0;
	margin-left: 0;
	margin-right: 0;
	& div {
		padding-left: 0;
		padding-right: 0;
	}
}
.loginButton {
	font-family: 'Josefin Sans', sans-serif;
	font-size: 18px;
	padding: 0;
	height: 0px;
	margin-left: 5px;
	& ::v-deep p {
		padding-top: 0;
	}
}
.secondaryTitle {
	font-size: 21px;
	padding-top: 5px;
	clear: both;
}
.secondaryLabel {
	font-family: 'Lato', sans-serif;
	font-size: 17px;
	font-weight: 300;
	margin: 0;
}
.link_margin {
	margin-bottom: 0px;
	margin-right: 0px;
}
.full_width {
	width: 50%;
}
.hr_content {
	position: relative;
	height: 5px;
	width: 45%;
	color: grey;
	margin-top: 0px;
	margin-bottom: 0px;
}
.invalid-feedback {
	display: none;
	visibility: hidden;
}
.card ::v-deep img {
	object-position: center;
	@include media-breakpoint-down(xs) {
		height: 120px;
	}
}
</style>

<i18n>
{
  "en": {
    "title": {
      "header": "Register",
      "secondHeader": "Once you’ve registered, you’ll be able to submit claims, check your coverage and see past claims.",
      "registerForm": "Create your account"

    }
  },
  "fr": {
    "title": {
      "header": "Inscription",
      "secondHeader": "Une fois votre inscription terminée, vous pourrez soumettre des demandes de règlement, consulter votre couverture et connaître l’historique de vos demandes de règlement.",
      "registerForm": "Créez votre compte"
    }
  }
}
</i18n>
