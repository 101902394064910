<template>
	<div>
		<Result
			class="confirm-registration"
			:icon-visible="false"
			:title="$t('title')"
			:content="$t('contentOne')"
			:secondary-content="email"
			secondary-content-class="bold"
			:hide-button="true"
		>
			<BRow no-gutters>
				<p>{{ $t('contentTwo') }}</p>
				<BCol cols="auto" class="mb-3 mr-3">
					<BaseButton
						:label="$t('button.confirm')"
						:aria-label="$t('button.confirm')"
						variant="primary"
						:automation-id="getAutomationId('confirm-email-update')"
						:data-tracking-id="getAutomationId('confirm-email-update')"
						type="submit"
						@click="submitConfirmation(true)"
					/>
				</BCol>
				<BCol cols="auto">
					<BaseButton
						:label="$t('button.cancel')"
						:aria-label="$t('button.cancel')"
						variant="secondary"
						:automation-id="getAutomationId('confirm-email-cancel')"
						:data-tracking-id="getAutomationId('confirm-email-cancel')"
						type="submit"
						@click="toggleModal"
					/>
				</BCol>
			</BRow>
		</Result>
		<ErrorModal
			class="error-modal"
			:data-test-automation-id="getAutomationId('confirm-email-modal')"
			:modal-show="modalShow"
			:error-title="$t('modal.title')"
			:error-message="$t('modal.text')"
			:has-close-button="false"
			@close="toggleModal"
		>
			<p class="modal-text">{{ $t('modal.textTwo') }}</p>
			<BaseButton
				:label="$t('modal.cancel')"
				:aria-label="$t('modal.cancel')"
				variant="primary"
				:automation-id="getAutomationId('confirm-email-update')"
				:data-tracking-id="getAutomationId('confirm-email-update')"
				type="submit"
				@click="cancel"
			/>
			<BaseButton
				:label="$t('modal.goBack')"
				:aria-label="$t('modal.goBack')"
				variant="secondary"
				:automation-id="getAutomationId('confirm-email-cancel')"
				:data-tracking-id="getAutomationId('confirm-email-cancel')"
				type="submit"
				@click="toggleModal"
			/>
		</ErrorModal>
	</div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BRow, BCol } from 'bootstrap-vue';
import Authentication from '@/models/Authentication';
import { LOGIN, SUCCESS_RESET_LOGIN } from '@/constants/Routes';
import BaseButton from '@/components/common/base/BaseButton';
import ErrorModal from '@/components/common/ErrorModal';
import Result from '@/components/result/Result';
import IdMixin from '@/mixins/id';

// @vue/component
@Component({
	name: 'RegistrationConfirmEmail',
	components: {
		BRow,
		BCol,
		BaseButton,
		ErrorModal,
		Result
	},
	mixins: [IdMixin],
	props: {
		automationId: {
			type: String,
			default: ''
		}
	}
})
export default class RegistrationConfirmEmail extends Vue {
	email = null;
	modalShow = false;

	created() {
		this.email = this.$route.params.newUsername;
	}

	submitConfirmation(confirmation) {
		this.$store.dispatch('updateLoading', true);
		Authentication.confirmReRegistrationEmail(
			confirmation,
			this.$route.params.token,
			this.$store.state.i18n.locale
		)
			.then((response) => {
				if (response?.status === 201) {
					this.$router.push({ name: SUCCESS_RESET_LOGIN });
				} else {
					if (response?.data?.message) {
						this.$store.dispatch('error', { name: 'confirmEmail', err: response?.data?.message });
					} else {
						this.$store.dispatch('error', { name: 'generic', err: 'generic' });
					}
				}
			})
			.catch((err) => {
				if (err) {
					this.$store.dispatch('error', err);
				} else {
					this.$store.dispatch('error');
				}
			})
			.finally(() => {
				this.$store.dispatch('updateLoading', false);
			});
	}

	cancel() {
		this.$router.push({ name: LOGIN });
	}

	//Toggle the error modal.
	toggleModal() {
		this.modalShow = !this.modalShow;
	}
}
</script>
<style lang="scss" scoped>
.error-modal ::v-deep .modal-content {
	width: 100%;
}

.error-modal ::v-deep .btn-outline-secondary {
	margin-left: 15px;
}

.error-modal ::v-deep .modal-header {
	font-size: 21px;
}

.error-modal ::v-deep .modal-body {
	padding-top: 20px;
}

.error-modal ::v-deep svg path {
	color: $orange-dark;
}

.modal-text {
	margin-top: 10px;
	margin-bottom: 30px;
}
</style>
<i18n>
{
    "en": {
		"title": "Confirm your new email address",
		"contentOne": "Before you can update your account, please confirm your new email address:",
		"contentTwo": "Is this the correct email you’d like to update your account with?",
        "button": {
            "confirm": "Yes, update",
            "cancel": "No, cancel"
        },
		"modal": {
			"title": "Are you sure you want to cancel?",
			"text": "If you choose to cancel, we will not update the email associated with your account. You can continue using the email currently associated with your account. ",
			"textTwo": "You can call our customer service team for further support.",
			"cancel": "Yes, cancel",
			"goBack": "No, go back"
		}
    },
    "fr": {
       	"title": "Confirmez votre nouvelle adresse courriel",
		"contentOne": "Avant que vous puissiez mettre à jour votre compte, confirmez votre nouvelle adresse courriel :",
		"contentTwo": "S’agit-il de l’adresse courriel que vous aimeriez ajouter à votre compte?",
        "button": {
            "confirm": "Confirmer la mise à jour",
            "cancel": "Annuler la mise à jour"
        },
			"modal": {
			"title": "Voulez-vous vraiment annuler la mise à jour?",
			"text": "L’adresse courriel associée à votre compte n’a pas été mise à jour. Continuez d’utiliser votre adresse courriel initiale ou appelez notre équipe du Service à la clientèle pour obtenir du soutien.",
			"textTwo": "N’hésitez pas à communiquer avec notre équipe du Service à la clientèle pour obtenir de l’aide.",
			"cancel": "Oui, annuler",
			"goBack": "Non, reviens"
		}
    }
}
</i18n>
