<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->
<template>
	<div>
		<BaseForm
			ref="registration-form"
			:data-tracking-id="getAutomationId('registration-form')"
			:automation-id="getAutomationId('registration-form')"
			@keydown.enter="submit"
			@submit="submitform"
		>
			<CertificateIdInput
				:value="member.memberCardId"
				:automation-id="getAutomationId('registration-form')"
				@input="updateMemberCardId"
			></CertificateIdInput>

			<PolicyIdInput
				:value="member.policyId"
				:automation-id="getAutomationId('registration-form')"
				require-complexity
				@input="updatePolicyId"
			></PolicyIdInput>

			<BirthDate
				:value="member.dob"
				class="mb-2"
				:label-birth-date="$t('label.dateOfBirth')"
				:automation-id="getAutomationId('registration-form')"
				@blur="updateBirthDate"
			/>

			<EmailInput
				:value="member.email"
				:label="$t('title.email')"
				class="mb-2 pb-4"
				:automation-id="getAutomationId('registration-form')"
				@input="updateEmail"
			></EmailInput>

			<PasswordRequirements />

			<PasswordInput
				:value="member.password"
				:vid="'register_password'"
				class="password_input"
				:label="$t('title.password')"
				:automation-id="getAutomationId('form')"
				required
				:min="8"
				:require-complexity="true"
				@input="updatePassword"
			></PasswordInput>

			<ConfirmInput
				:value="member.confirmPassword"
				class="password_input"
				:field="$t('title.password')"
				password
				type="password"
				:confirmation="member.password"
				:label="$t('title.passwordConfirm')"
				:automation-id="getAutomationId('registration-form')"
				@input="updateConfirmPassword"
			></ConfirmInput>

			<div class="d-flex mt-1 pb-3">
				<BaseButton
					class="m-0 registerButton"
					variant="primary"
					:label="$t('button.submit')"
					pill
					is-right
					type="submit"
					:automation-id="getAutomationId('form-submit')"
					data-tracking-id="registration-form-submit-button"
				></BaseButton>
			</div>
		</BaseForm>
	</div>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->
<script>
/**
 * This component is responsible to render registration content
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseForm from '@/components/common/base/BaseForm';
import EmailInput from '@/components/common/EmailInput';
import BirthDate from '@/components/common/BirthDate';
import CertificateIdInput from '@/components/common/CertificateIdInput';
import PolicyIdInput from '@/components/common/PolicyIdInput';
import PasswordInput from '@/components/common/PasswordInput';
import ConfirmInput from '@/components/common/ConfirmInput';
import BaseButton from '@/components/common/base/BaseButton';
import PasswordRequirements from '@/components/common/PasswordRequirements';
import IdMixin from '@/mixins/id.js';
import { required } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
extend('required', required);

// @vue/component
@Component({
	name: 'RegistrationForm',
	components: {
		BaseForm,
		EmailInput,
		BirthDate,
		CertificateIdInput,
		PolicyIdInput,
		PasswordInput,
		ConfirmInput,
		BaseButton,
		PasswordRequirements
	},
	mixins: [IdMixin],
	props: {
		value: {
			type: Object,
			default: null
		},
		errorMsg: {
			type: String,
			default: ''
		},
		automationId: {
			type: String,
			default: ''
		}
	},
	watch: {
		errorMsg: {
			immediate: true,
			deep: true,
			handler: function (val) {
				if (val !== '') {
					this.collapsed = true;
				} else {
					if (!this.isEmailMessageEnabled && !this.isPasswordMessageEnabled) {
						this.collapsed = false;
					}
				}
			}
		}
	}
})
export default class RegistrationForm extends Vue {
	data() {
		return {
			icon: ['fal', 'chevron-right'],
			collapsed: false,
			toggleNo: false,
			toggleNoWasClicked: false
		};
	}

	get member() {
		return this.value ? this.value : {};
	}

	/**
	 * Update email
	 * @param {string} email user email
	 */
	updateEmail(email) {
		this.$emit('input', { ...this.value, email });
	}
	/**
	 * Update date of Birth
	 * @param {string} birthDate user dob
	 */
	updateBirthDate(birthDate) {
		const dob = birthDate;
		this.$emit('input', { ...this.value, dob });
	}
	/**
	 * Update member card ID
	 * @param {string}  memberCardId card Id
	 */
	updateMemberCardId(memberCardId) {
		this.$emit('input', { ...this.value, memberCardId });
	}
	/**
	 * Update policy ID
	 * @param {string} policyId user policyID
	 */
	updatePolicyId(policyId) {
		this.$emit('input', { ...this.value, policyId });
	}
	/**
	 * Update password
	 * @param {string} password user password
	 */
	updatePassword(password) {
		this.$emit('input', { ...this.value, password });
	}
	/**
	 * Update password confirmation input
	 * @param {string} password user password
	 */
	updateConfirmPassword(confirmPassword) {
		this.$emit('input', { ...this.value, confirmPassword });
	}

	/**
	 * Submit form
	 */
	submitform() {
		this.$emit('submit');
	}
}
</script>
<!-- *************************************************************************
	STYLE
	************************************************************************* -->
<style lang="scss" scoped>
ul {
	padding-left: 20px;
}
.registerButton {
	width: 200px;
	height: 42px;
	border: 1px solid #0079ad;
	border-radius: 30px;
	font-family: 'Josefin Sans', sans-serif;
	font-size: 16px;
	font-weight: normal;
}

.password_input ::v-deep .svg-inline--fa {
	color: #808080;
}
</style>

<!-- *************************************************************************
	Translation
	************************************************************************* -->
<i18n>
{
  "en": {
    "title": {
      "email": "Email address",
      "password": "Password",
      "passwordConfirm": "Confirm password"
    },
    "label": {
      "dateOfBirth": "Date of birth"
    },
    "button": {
      "submit": "Create your account"
    }
  },
  "fr": {
    "title": {
      "email": "Adresse courriel",
      "password": "Mot de passe",
      "passwordConfirm": "Confirmer le mot de passe"
    },
    "label": {
      "dateOfBirth": "Date de naissance"
    },
    "button": {
      "submit": "Créez votre compte"
    }
  }
}
</i18n>
